<template>
  <div class="UnitBBRSchema">
    <span class="Headline">{{ unit.getFullTitle() }}</span>

    <!-- BBR rows -->
    <div class="Row">
      <span>{{ mixWB('ADDRESS') }}</span>
      <span>{{ address }}</span>
    </div>
    <template v-if="building">
      <div class="Row">
        <span>{{ mixWB('CADASTRAL_NUMBER') }}</span>
        <span>{{ building.getCadastralNumber() || '?' }}</span>
      </div>
      <div class="Row">
        <span>{{ mixWB('LAND_OWNER_ASSCOSIATION') }}</span>
        <span>
          {{
            building.getLandOwnerAssosiationName() || '?'
          }}
        </span>
      </div>
      <div class="Row">
        <span>{{ mixWB('YEAR_BUILT') }}</span>
        <span>{{ building.getYearBuilt() || '?' }}</span>
      </div>
      <div class="Row">
        <span>{{ mixWB('REMODELING_YEAR') }}</span>
        <span>{{ building.getRemodelingYear() || '?' }}</span>
      </div>
      <div class="Row">
        <span>{{ mixWB('BUILT_AREA') }}</span>
        <span>{{ building.getBuiltArea() || '0 ' + mixWB('M2') }}</span>
      </div>
      <div class="Row">
        <span>{{ mixWB('UTILIZED_ATTIC_AREA') }}</span>
        <span>{{ building.getUtilizedAtticArea() || '0 ' + mixWB('M2') }}</span>
      </div>
      <div class="Row">
        <span>{{ mixWB('BASEMENT_AREA') }}</span>
        <span>{{ building.getBasementArea() || '0 ' + mixWB('M2') }}</span>
      </div>
      <div class="Row">
        <span>{{ mixWB('TOTAL_LIVING_AREA') }}</span>
        <span>{{ building.getTotalLivingArea() || '0 ' + mixWB('M2') }}</span>
      </div>
      <div class="Row">
        <span>{{ mixWB('TOTAL_BUSINESS_AREA') }}</span>
        <span>{{ building.getTotalBusinessArea() || '0 ' + mixWB('M2') }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UnitBBRSchema',
  props: {
    unit: {
      type: Object,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'screeningUnits',
    ]),
    building() {
      if (this.unit.type === 'building') {
        return this.unit
      }

      return this.screeningUnits.buildings.find((x) => x.id === this.unit.data.buildingID)
    },
  },
}
</script>

<style lang="stylus" scoped>
  .UnitBBRSchema
    border 1px solid $color_grey_dark
    margin-bottom 20px
    span
      display block

  .Headline
    background-color $color_grey_dark
    color #fff
    padding 10px

  .Row
    display flex
    &:nth-child(even)
      background-color $color_grey_lightest
    span
      padding 5px 10px
      &:first-child
        width 220px
      &:last-child
        flex-grow 2
</style>
