<template>
  <div class="FinalWords">
    <!-- Page 1 -->
    <div
      id="appendix-a"
      class="Page">
      <PageTag :pageName="mixWB('APPENDIX') + ' A'" />
      <div class="InnerPage js-inner-page">
        <span
          id="appendix-1"
          class="AppendixTitle js-headline-appendix">Bilag A</span>
        <h1>Håndtering af materialer med pcb, tungmetaller og asbest</h1>

        <h2>Generelt</h2>
        <p>
          Ved materialer som f.eks. indeholder PCB og tungmetaller i forurenede koncentrationer,
          skal materialet bortskaffes som PCB-holdigt affald.
        </p>
        <p>
          Ved materialer som f.eks. indeholder PCB i forurenede koncentrationer og tungmetaller
          i farlige koncentrationer, skal materialet bortskaffes som tungmetalholdigt
          farligt affald.
        </p>

        <h2>Forurenet affald</h2>
        <p>
          Det skal i forbindelse med deklaration af affaldet oplyses at PCB- eller
          tungmetalindholdet er mellem grænseværdier.
        </p>

        <h2>PCB</h2>
        <p>
          Ved PCB-holdige malinger og tapeter skal materialerne afrenses/fjernes på korrekt vis,
          så affald kan bortskaffes separat som PCB-holdigt affald, sorteret som forurenet eller
          farligt affald iht. analyseresultater.
        </p>
        <p>
          I forhold til arbejdsgang og arbejdsforhold anbefales det at elementer (brændbare) inkl.
          PCB-holdig maling bortskaffes samlet som PCB-holdigt affald, sorteret som forurenet
          eller farligt affald iht. analyseresultater.
        </p>

        <h3>Tilstødende materialer</h3>
        <p>
          PCB-indhold: 0,10-50 mg/kg – forurenet affald. <br/>
          Minimum 0,5 cm af tilstødende/bagvedliggende materiale skal afrenses på korrekt vis,
          affald skal håndteres og bortskaffes som PCB-holdigt affald.
        </p>

        <h3>Tungmetaller</h3>
        <p>
          Ved tungmetalholdige malinger og fliser skal materialerne afrenses/fjernes på korrekt
          vis, så affald kan bortskaffes separat som tungmetalholdigt affald, sorteret som
          forurenet eller farligt affald iht. analyseresultater.
        </p>
        <p>
          I forhold til arbejdsgang og arbejdsforhold anbefales det at elementer (brændbare) inkl.
          tungmetalholdig maling bortskaffes samlet som tungmetalholdigt affald, sorteret som
          forurenet eller farligt affald iht. analyseresultater.
        </p>
        <p>
          I forhold til arbejdsgang og arbejdsforhold anbefales det at metaller med tungmetalholdig
          maling inkl. tungmetalholdig maling bortskaffes samlet som tungmetalholdigt affald,
          sorteret som forurenet eller farligt affald iht. analyseresultater.
        </p>

        <h2>Asbest</h2>
        <p>
          Ved asbestholdige tagplader, skal tagplader nedtages på korrekt vis og affald skal
          håndteres, mærkes og bortskaffes som asbestholdigt affald.
        </p>
        <p>
          Ved asbestholdige skifereternitplader, skal skifereternitplader nedtages på korrekt
          vis og affald skal håndteres, mærkes og bortskaffes som asbestholdigt farligt affald.
        </p>
        <p>
          Ved asbestholdig fliseklæb skal fliseklæb afrenses på korrekt vis og fliseklæb og fliser
          skal håndteres, mærkes og bortskaffes som asbestholdigt farligt affald.
        </p>
        <p>
          Ved asbestholdige gulvbelægninger skal gulvbelægninger fjernes på korrekt vis, affald
          skal håndteres, mærkes og bortskaffes som asbestholdigt farligt affald.
        </p>

        <h2>Håndtering og sikkerhed</h2>
        <h3>PCB</h3>
        <p>
          Det er Entreprenørens/Arbejdsgivers ansvar at Entreprenørens/Arbejdsgiverens
          medarbejdere er korrekt uddannet og instrueret i at udføre arbejdet med PCB korrekt
          iht. BAR’s Branchevejledning ’Håndtering og fjernelse af PCB-holdige bygningsmaterialer’
          som Arbejdstilsynet henviser til. <br/>
          Derudover henvises der til ’PCB vejledning’ fra Dansk Asbestforening og www.pcb-guiden.dk.
        </p>

        <h3>Tungmetaller</h3>
        <p>
          Det er Entreprenørens/Arbejdsgivers ansvar at Entreprenørens/Arbejdsgiverens medarbejdere
          er korrekt instrueret i og udføre arbejdet korrekt iht. til Arbejdstilsynets
          regler samt At-vejledning C.0.8. <br/>
          Derudover henvises der til ’Bly vejledning’ fra Dansk Asbestforening.
        </p>
      </div>

      <Footer :text="mixWB('APPENDIX') + ' A'" />
    </div>

    <!-- Page 2 -->
    <div class="Page">
      <div class="InnerPage js-inner-page">
        <h3>Asbest</h3>
        <p>
          Det er Entreprenørens/Arbejdsgivers ansvar at Entreprenørens/Arbejdsgiverens medarbejdere
          er korrekt instrueret i at udføre arbejdet korrekt iht. Arbejdstilsynets regler i
          bekendtgørelse nr. 1502 af 21. december 2004, samt at de overholder kravene til
          uddannelse som nævnt i kap. 7, § 27, samt iht. At-vejledning C.2.2. Derudover henvises der
          til ’Asbestvejledning’ fra Dansk Asbestforening.
        </p>

        <h3>Generelt</h3>
        <p>
          Både kommunen og Arbejdstilsynet skal gribe ind, hvis de bliver opmærksomme på, at en
          arbejdsplads er sundhedsfarlig. <br/>
          Arbejdsstedet skal tydeligt skiltes med at der er PCB, tungmetaller og asbest på
          arbejdsstedet.
        </p>
      </div>

      <Footer :text="mixWB('APPENDIX') + ' A'" />
    </div>

    <!-- Page 3 -->
    <div
      id="appendix-b"
      class="Page">
      <PageTag :pageName="mixWB('APPENDIX') + ' B'" />

      <div class="InnerPage js-inner-page">
        <span
          id="appendix-2"
          class="AppendixTitle js-headline-appendix">Bilag B</span>
        <h1>Vejledende generel beskrivelse af forholdsregler</h1>

        <p><i>
          ... ved arbejde med materialer indeholdende pcb, tungmetaller og asbest samt
          bortskaffelse af disse.
        </i></p>
        <p>
          Nedenstående retningslinjer skal betragtes som generelle anbefalinger, der kan anvendes
          som baggrundsmateriale ved vurdering af de enkelte sager.
        </p>
        <p>
          Vejledningen skal altid tilpasses det konkrete projekt, hvor andre, evt. i det enkelte
          projekt mere hensigtsmæssige, forholdsregler kan tages i brug.
        </p>
        <p>
          Sidst i notatet er der anført henvisninger til myndighedernes krav og anbefalinger
          samt mere udførlige beskrivelser af arbejdsmetoder mm.
        </p>
        <p>
          Der kan være andre miljøproblematiske stoffer i et byggeri, men de ovennævnte stoffer
          er de mest almindelige.
        </p>
        <p>
          Det gælder generelt for alle typer af arbejde indeholdende farlige stoffer, at det er
          entreprenøren/nedbryderen, der konkret vurderer, hvordan arbejdet tilrettelægges og
          herunder sikrer:
        </p>
        <ul>
          <li>At unge under 18 år ikke arbejder med farlige stoffer</li>
          <li>At medarbejderne instrueres grundigt forud for arbejde med farlige stoffer</li>
          <li>At de foreskrevne velfærdsfaciliteter stilles til rådighed for medarbejderne</li>
          <li>At der udarbejdes en APV og arbejdsplan forud for arbejdet</li>
          <li>
            At arbejdet forud skal anmeldes til kommunen, som anviser bortskaffelsen af affald
          </li>
          <li>
            At arbejde med asbest indendørs og arbejde med støvende asbest forud anmeldes til AT
          </li>
        </ul>
        <p>
          Hvis flere entreprenører skal arbejde på samme byggeplads og ved mere end 10 medarbejdere
          på pladsen samtidigt, er det bygherres ansvar, at der udarbejdes en PSS
          (plan for sikkerhed og sundhed).
        </p>
        <p>
          Det anbefales, at der i konkrete sager tages kontakt til myndighederne (AT og kommune) i
          tilfælde af tvivlsspørgsmål.
        </p>

        <h2>Liste over relevante publikationer med krav og anvisninger</h2>
        <p>PCB:</p>
        <ul>
          <li>Asbestforeningen: PCB Vejledning</li>
          <li>BAR: Håndtering og fjernelse af PCB-holdige bygningsmaterialer</li>
          <li>Miljøstyrelsen: Vejledende udtalelse om håndtering af PCB-holdigt bygge- og
            anlægsaffald</li>
          <li>AT: Intern instruks IN-9-3 om PCB i bygninger</li>
          <li>SBi: SBi anvisning 242 – Renovering af bygninger med PCB</li>
        </ul>
        <p>Tungmetaller:</p>
        <ul>
          <li>Asbestforeningen: Bly Vejledning</li>
          <li>AT: Vejledning C.0.8 om Metallisk bly og Blyforbindelser</li>
        </ul>
        <p>Tungmetaller:</p>
        <ul>
          <li>Asbestforeningen: Asbest Vejledning</li>
          <li>AT: Vejledning C2.2, juli 2005</li>
          <li>BAR: Når du støder på Asbest, Regler og Baggrund</li>
          <li>BAR: Når du støder på Asbest, Sådan gør du</li>
        </ul>
      </div>

      <Footer :text="mixWB('APPENDIX') + ' B'" />
    </div>
  </div>
</template>

<script>
import PageTag from '@/components/PDF/PageTag.vue'
import Footer from '@/components/PDF/Footer.vue'

export default {
  name: 'FinalWords',
  components: {
    PageTag,
    Footer,
  },

}
</script>

<style lang="stylus" scoped>
  .FinalWords
    display block
    p
      padding-bottom 10px

  .Page
    page()
    ul
      margin-top -10px
      margin-left 20px
      padding-bottom 10px

  .InnerPage
    innerPageReport()
    h2
      padding-top 10px

  .AppendixTitle
    text-align center
    font-size 1.5rem
    text-transform uppercase
    font-weight bold
    margin-bottom 20px
</style>
