<template>
  <div class="MissingThings">
    <PageLoading v-if="isLoading" />

    <div
      class="InnerWrap"
      v-if="!isLoading">
      <MissingItem
        :text="mixWB('BUILDER_NAME')"
        name="builder"
        :isDone="!!builder.fullName"
        @item-click="onItemClick" />
    </div>

    <Dialog
      :isShowing="showBuilderModal"
      :useComponent="BuilderModal"
      :componentProps="{}"
      @builder-updated="onDialogClose"
      @close="onDialogClose" />
  </div>
</template>

<script>
import PageLoading from '@/components/PDF/PageLoading.vue'
import { mapGetters } from 'vuex'
import BuilderModal from '@/components/PDF/Modals/BuilderModal.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import MissingItem from '@/components/PDF/MissingItem.vue'
import EventBus from '@/EventBus'

export default {
  name: 'MissingThings',
  data() {
    return {
      BuilderModal,
      isLoading: true,
      showBuilderModal: false,
    }
  },
  computed: {
    ...mapGetters([
      'metaData',
      'buildings',
      'selectedBuildings',
      'addressImages',
      'addressImagesLoaded',
      'currentScreeningMetaDataLoaded',
      'currentScreeningBuildingsLoaded',
      'currentScreeningLoaded',
    ]),
    builder() {
      return this.metaData.builder || {}
    },
  },
  methods: {
    onItemClick(name) {
      if (name === 'builder') {
        this.showBuilderModal = true
      }
    },
    onDialogClose() {
      this.showBuilderModal = false
      EventBus.$emit('page-updated')
    },
  },
  components: {
    PageLoading,
    MissingItem,
    Dialog,
  },
  created() {
    EventBus.$on('open-modal', this.onItemClick)
  },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (
        !this.currentScreeningMetaDataLoaded
        || !this.currentScreeningBuildingsLoaded
        || !this.addressImagesLoaded
        || !this.currentScreeningLoaded
      ) {
        return
      }

      clearInterval(waitForLoad)
      this.isLoading = false
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .MissingThings
    position relative
    width 794px
    min-height 100px
    padding 20px 20px 10px
    background-color #fff
    margin 20px auto
    box-shadow $box_shadow_1
    @media print
      display none

  .InnerWrap
    display flex
    justify-content space-between
    flex-wrap wrap
</style>
