<template>
  <div
    class="FloorPlanWithSamples"
    :class="{ Landscape: landscapeMode, Portrait: !landscapeMode }">
    <div class="Header">
      <span>{{ unit.getFullTitle() }}</span> {{ headerSamplesText }}
    </div>
    <div class="Content">
      <div
        class="FloorPlanWrapper"
        v-if="image">
        <img
          :src="image"
          @load="imageIsLoaded" />
        <div
          v-if="imageLoaded"
          class="MarkerContainer"
          ref="container">
          <a
            :href="'#sample-p' + sample.number"
            v-for="sample in samples"
            :key="sample.id"
            class="Marker"
            :class="sample.result"
            :style="getMarkerStyle(sample.position)"
            @click.prevent="onSampleClick(sample.number)">
            {{ sample.text }}
          </a>
        </div>
      </div>
      <div
        v-if="!image && !failed"
        class="ProgressWrap">
        <Circular />
      </div>
      <div
        v-if="failed"
        class="FailedWrap">
        <WarningIcon />
      </div>
    </div>
  </div>
</template>

<script>
import { rotateAndResizeImage } from '@/globals/javascript/_util/images'
import WarningIcon from '@/components/Icons/WarningIcon.vue'
import Circular from '@/components/Progress/Circular.vue'
import { mapGetters } from 'vuex'
import * as Sentry from '@sentry/browser'
import { PDFTracker } from '@/globals/javascript/_util/PDFTracker'
import { scrollTo } from '@/globals/javascript/_util/util'

export default {
  name: 'FloorPlanWithSamples',
  props: {
    unit: {
      type: Object,
      required: true,
    },
    landscapeMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      imageLoaded: false,
      failed: false,
      containerBounds: undefined,
      image: undefined,
    }
  },
  computed: {
    ...mapGetters([
      'screeningSamples',
      'screeningTestResults',
    ]),
    samples() {
      const getResult = (sampleResult) => {
        if (sampleResult) {
          if (sampleResult.overallResult === 2
            || sampleResult.metalsAboveThreshold
            || sampleResult.cpScreeningAutoProven) {
            return 'ItemHazardousWaste'
          }

          if (sampleResult.overallResult === 1) {
            return 'ItemContaminated'
          }

          if (sampleResult.overallResult === 0) {
            return 'ItemClean'
          }
        }

        return 'ItemNotAnalysed'
      }

      return this.screeningSamples
        .filter((sample) => sample.floorPlanUnit === this.unit.id)
        .map((sample) => {
          const result = this.screeningTestResults && this.screeningTestResults.find(
            (result) => result.sampleNumber === sample.sampleNumber,
          )
          return {
            id: sample.id,
            number: sample.sampleNumber,
            text: sample.getNumber(),
            position: sample.floorPlanPosition,
            result: getResult(result),
          }
        })
    },
    headerSamplesText() {
      if (!this.samples.length) {
        return ''
      }
      return `${ this.samples.length } ${ this.samples.length === 1 ? this.mixWB('SAMPLE') : this.mixWB('SAMPLES') }`
    },
    originalImage() {
      return this.unit.getFloorPlan()[0].base.path
    },
  },
  watch: {
    landscapeMode() {
      this.loadImage()
    },
  },
  methods: {
    loadImage() {
      PDFTracker.track(this.originalImage)

      this.mixGetDownloadURL({ path: this.originalImage }).then((url) => {
        if (!this.landscapeMode) {
          this.image = url
          return url
        }

        return rotateAndResizeImage({ url })
          .then((blob) => {
            this.image = URL.createObjectURL(blob)
          })
      }).catch((e) => {
        this.failed = true
        PDFTracker.failed(this.originalImage)
        Sentry.captureException(e)
      })
    },
    imageIsLoaded() {
      this.imageLoaded = true
      PDFTracker.loaded(this.originalImage)
    },
    getMarkerStyle({ x, y }) {
      if (x === null || y === null) return {}

      if (this.landscapeMode) {
        return {
          left: `${ y }%`,
          bottom: `${ x }%`,
        }
      }

      return {
        top: `${ y }%`,
        left: `${ x }%`,
      }
    },
    onSampleClick(sampleNumber) {
      const element = document.getElementById(`sample-p${ sampleNumber }`)

      if (!element) {
        return
      }

      scrollTo({ element, offset: -60, duration: 500 })
    },
  },
  components: {
    WarningIcon,
    Circular,
  },
  mounted() {
    this.loadImage()
  },
  destroyed() {
    URL.revokeObjectURL(this.image)
    PDFTracker.track(this.originalImage)
  },
}
</script>

<style lang="stylus" scoped>
  .FloorPlanWithSamples:first-of-type
    margin-bottom 10px
  .Header
    padding 10px
    background $color_grey_dark
    color white
    display flex
    justify-content space-between
    span
      color inherit
      display inline
  .Content
    border 1px solid $color_grey_dark
    background $color_grey_lighter
    padding 10px
    text-align center
  .FloorPlanWrapper
    position relative
    display inline-block
    max-width 100%
    max-height 100%
    img
      width initial
      height initial
      max-width 100%
      max-height 100%
      .Landscape &
        max-height 400px
  .Portrait img
    max-width 100%
    max-height: 883px;
  .MarkerContainer
    overflow hidden
    position absolute
    top 0
    bottom 0
    left 0
    right 0
  .Marker
    position absolute
    display flex
    box($markerWidth = 25px)
    align-items center
    justify-content center
    border-radius: 50%
    margin-left -($markerWidth / 2)
    margin-top -($markerWidth / 2)
    transition opacity 300ms ease
    color white
    font-weight normal
    font-size 0.665rem
    .Landscape &
      margin-top 0
      margin-bottom -($markerWidth / 2)
    &.ItemHazardousWaste
      background-color $color_report_red
    &.ItemContaminated
      background-color $color_report_yellow
      color $color_grey_dark
    &.ItemClean
      background-color $color_report_green
    &.ItemNotAnalysed
      background $color_grey_light
      border 1px solid $color_grey
  .FailedWrap >>> svg
    height 100px
  .ProgressWrap
    position relative
    height 100px
</style>
