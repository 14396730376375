<template>
  <div class="BuildingPage">
    <PageTag
      v-if="index === 0"
      :pageName="mixWB('BUILDINGS')" />

    <PageLoading
      v-if="isLoading"
      :isLoading="isLoading" />

    <div
      v-if="!isLoading"
      class="InnerPage js-inner-page">

      <!-- Page title -->
      <h1
        v-if="index === 0"
        class="js-headline"
        id="buildings">5. {{ mixWB('BUILDINGS_AND_PCB_SCHEME') }}</h1>

      <!-- BBR data -->
      <div class="BBR">
        <span class="Headline">{{ getBuildingTitle(building) }}</span>
        <div
          class="Row"
          v-for="item in dataKeys"
          :key="item.key">
          <span>{{ mixWB(item.wordbook) }}</span>
          <span>{{ getBBRDataValue(building, item) }}</span>
        </div>
      </div>

      <!-- Images -->
      <div class="ImageList">
        <div
          class="Image"
          v-for="image in buildingImages"
          :key="image.base.path">
          <MultiImage
            :image="image" />
        </div>
      </div>

      <!-- PCB scheme -->
      <div class="PCBScheme">
        <div class="Row Headline">
          <span class="Question">{{ mixWB('PCB_SCREENING') }}</span>
          <span class="Yes">{{ mixWB('YES') }}</span>
          <span class="No">{{ mixWB('NO') }}</span>
          <span class="Comment">{{ mixWB('DONT_KNOW') }} / {{ mixWB('COMMENTS') }}</span>
        </div>
        <div
          class="Row"
          v-for="answer in psbScreeningAnswers"
          :key="answer.id">
          <span class="Question">{{ answer.text }}</span>
          <span class="Yes">{{ answer.yes }}</span>
          <span class="No">{{ answer.no }}</span>
          <span class="Comment">{{ answer.dontKnow }} {{ answer.comment }}</span>
        </div>
      </div>

      <!-- PCB caption -->
      <span class="PCBCaption">{{ mixWB('PCB_SCHEME_REPORT_CAPTION_TEXT') }}</span>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageLoading from '@/components/PDF/PageLoading.vue'
import PageTag from '@/components/PDF/PageTag.vue'
import MultiImage from '@/components/Images/MultiImage'
import Footer from '@/components/PDF/Footer.vue'
import { getBuildingTitle, getBBRDataValue } from '@/components/helpers'
import { mapGetters } from 'vuex'

export default {
  name: 'BuildingPage',
  props: {
    index: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      getBuildingTitle,
      getBBRDataValue,
      dataKeys: [
        {
          key: 'MatrNr',
          wordbook: 'CADASTRAL_NUMBER',
          type: '',
        },
        {
          key: 'Landsejerlavkode', // TODO: Find final value somehow
          wordbook: 'LAND_OWNER_ASSCOSIATION',
          type: '',
        },
        {
          key: 'OPFOERELSE_AAR',
          wordbook: 'YEAR_BUILT',
          type: 'year',
        },
        {
          key: 'OMBYG_AAR',
          wordbook: 'REMODELING_YEAR',
          type: 'year',
        },
        {
          key: 'BYG_BEBYG_ARL',
          wordbook: 'BUILT_AREA',
          type: 'm2',
        },
        {
          key: 'UTILIZED_ATTIC_AREA',
          wordbook: 'UTILIZED_ATTIC_AREA',
          type: 'm2',
        },
        {
          key: 'BASEMENT_AREA',
          wordbook: 'BASEMENT_AREA',
          type: 'm2',
        },
        {
          key: 'BYG_BOLIG_ARL_SAML',
          wordbook: 'TOTAL_LIVING_AREA',
          type: 'm2',
        },
        {
          key: 'ERHV_ARL_SAML',
          wordbook: 'TOTAL_BUSINESS_AREA',
          type: 'm2',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'addressImages',
      'pcbScreeningQuestions',
      'screeningPCBScreenings',
    ]),
    buildingImages() {
      return this.addressImages.buildings[this.building.buildingID].profile
    },
    psbScreeningData() {
      if (!this.pcbScreeningQuestions || !this.screeningPCBScreenings) {
        return {}
      }
      const screening = this.screeningPCBScreenings.find((x) => x.id === this.building.buildingID)

      return screening ? screening.data : {}
    },
    psbScreeningAnswers() {
      return this.pcbScreeningQuestions.reduce((prev, question) => {
        if (!this.psbScreeningData[question.id]) {
          return prev
        }

        const data = {
          id: question.id,
          text: '',
          no: '',
          yes: '',
          dontKnow: '',
          comment: '',
        }

        const { answer } = this.psbScreeningData[question.id]

        data.text = this.mixWB(question.wordbook)
        data.no = answer === 0 ? 'X' : ''
        data.yes = answer === 1 ? 'X' : ''
        data.dontKnow = answer === 2 ? 'X' : ''
        data.comment = this.psbScreeningData[question.id].comment || ''

        if (data.dontKnow && data.comment) {
          data.dontKnow = 'X - '
        }

        prev.push(data)
        return prev
      }, [])
    },
  },
  components: {
    PageLoading,
    MultiImage,
    PageTag,
    Footer,
  },
}
</script>

<style lang="stylus" scoped>
  .BuildingPage
    page()

  .InnerPage
    innerPageReport()

  .BBR
    border 1px solid $color_grey_dark
    margin-bottom 20px
    .Headline
      background-color $color_grey_dark
      color #fff
      padding 10px
    .Row
      display flex
      &:nth-child(even)
        background-color $color_grey_lightest
      span
        padding 5px 10px
        font-size 0.875rem
        &:first-child
          width 220px
        &:last-child
          flex-grow 2

  .ImageList
    display flex
    justify-content center
    margin-bottom 20px
    .Image
      box(200px)
      margin 0 10px
      border 1px solid $color_grey_lightest

  .PCBScheme
    border 1px solid $color_grey_dark
    margin-bottom 5px
    .Row
      display flex
      align-items center
      padding 5px 10px
      span
        font-size 0.875rem
      &:nth-child(even)
        background-color $color_grey_lightest
      &.Headline
        background-color $color_grey_dark
        padding 10px
        span
          color #fff
        .Question
          font-size 1rem
      .Question
        flex-grow 2
      .Yes,
      .No
        width 50px
        flex-shrink 0
        text-align center
      .Comment
        width 190px
        flex-shrink 0

  .PCBCaption
    color $color_grey
    font-size 0.75rem
    font-style italic
</style>
