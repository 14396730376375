<template>
  <div
    id="assessment-and-recommendation"
    class="CategoryList">
    <div class="Page">
      <PageTag
        :pageName="mixWB('ASSESSMENT_AND_RECOMMENDATION')" />

      <PageLoading v-if="isLoading" />

      <div class="InnerPage js-inner-page">
        <h1
          class="js-headline"
          id="category-list">8. {{ mixWB('ASSESSMENT_AND_RECOMMENDATION') }}</h1>

        <CategoryListIntro />

        <h2>{{ mixWB('OVERVIEW_OF_ALL_CATEGORIES') }}</h2>
        <div class="ListWrap">
          <div class="Row ListHeader">
            <span class="Title">{{ mixWB('CATEGORY') }}</span>
            <span class="TypeCount">{{ mixWB('NUMBER_OF_REGISTERED_TYPES') }}</span>
          </div>
          <a
            class="Row"
            v-for="(category, index) in mutatedCategories"
            :href="'#' + category.id"
            :key="category.id"
            @click.prevent="onRowClick(category.id)">
            <span class="Title">{{ index + 1 }}. {{ category.title }}</span>
            <span
              v-if="category.typeCount"
              class="TypeCount">{{ category.typeCount }}</span>
            <span
              v-else
              class="NoTypes">
              {{ mixWB('NO_REGISTERED_TYPES_FOR_THIS_CATEGORY') }}
            </span>
            <span
              v-if="category.typeCount && category.pageNumber"
              class="PageNumber">{{ mixWB('PAGE') }} {{ category.pageNumber }}</span>
            <span
              v-if="category.typeCount"
              class="ShowMore">
              <span>{{ mixWB('SHOW') }}</span>
            </span>
          </a>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import PageTag from '@/components/PDF/PageTag.vue'
import PageLoading from '@/components/PDF/PageLoading.vue'
import CategoryListIntro from '@/components/PDF/CategoryListIntro.vue'
import Footer from '@/components/PDF/Footer.vue'
import { mapGetters } from 'vuex'
import { sortBy } from 'lodash-es'
import EventBus from '@/EventBus'
import { scrollTo } from '@/globals/javascript/_util/util'
import {
  getClosestByClass,
  queryAllByClass,
  queryByClass,
} from '@/globals/javascript/_util/find-elements'

export default {
  name: 'CategoryList',
  data() {
    return {
      isLoading: true,
      categoryPageNumbers: {},
    }
  },
  computed: {
    ...mapGetters([
      'categories',
      'screeningTypes',
    ]),
    mutatedCategories() {
      const categoryItems = this.categories.map((category) => {
        const item = {
          id: category.id,
          title: this.mixWB(category.wordbook),
          position: category.position,
          placeSortValue: null,
          typeCount: 0,
          pageNumber: this.categoryPageNumbers[category.id] || null,
        }

        if (category.place === 'outside') {
          item.placeSortValue = 1
        }
        if (category.place === 'inside') {
          item.placeSortValue = 2
        }
        if (category.place === 'other') {
          item.placeSortValue = 3
        }

        return item
      })

      this.screeningTypes.forEach((type) => {
        const categoryItem = categoryItems.find((x) => x.id === type.categoryID)

        categoryItem.typeCount += 1
      })

      return sortBy(categoryItems, ['placeSortValue', 'position'])
    },
  },
  methods: {
    onRowClick(categoryID) {
      const element = document.getElementById(categoryID)

      if (!element) {
        return
      }

      scrollTo({ element, offset: -60, duration: 500 })
    },
    onUpdatePage() {
      const categoryHeaders = queryAllByClass('js-category-title')
      const categoryPageNumbers = categoryHeaders.reduce((prev, header) => {
        const innerPage = getClosestByClass('js-inner-page', header)
        const outerPage = innerPage.parentElement
        const pageNumber = queryByClass('js-page-number', outerPage)
        prev[header.id] = pageNumber.innerHTML
        return prev
      }, {})

      this.categoryPageNumbers = categoryPageNumbers
      this.isLoading = false
      EventBus.$emit('report-ready')
    },
  },
  components: {
    PageTag,
    PageLoading,
    CategoryListIntro,
    Footer,
  },
  created() {
    EventBus.$on('table-of-content-update', this.onUpdatePage)
  },
  destroyed() {
    EventBus.$off('table-of-content-update', this.onUpdatePage)
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryList
    display block

  .Page
    page()

  .InnerPage
    innerPageReport()

  .ListWrap
    border 1px solid $color_grey_dark

  .Row
    display flex
    align-items center
    span
      padding 5px 10px
    &:nth-child(even)
      background-color $color_grey_lightest

  .ListHeader
    background-color $color_grey_dark
    span
      color #fff
      padding 10px

  .Title
    width 200px
    flex-shrink 0
  .TypeCount
    flex-grow 2
  .NoTypes
    flex-grow 2
    font-style italic
  .PageNumber
    width 120px
  .ShowMore
    width 60px
    height 25px
    display flex
    align-items center
    justify-content center
    border 1px solid $color_grey_light
    margin-right 2px
    overflow hidden
    .Arrow
      transform rotate(45deg)
      border-right 2px solid $color_grey
      border-bottom 2px solid $color_grey
      box(12px)
      margin-top -6px
</style>
