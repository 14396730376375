<template>
  <div class="UnitPage">
    <PageTag
      v-if="index === 0"
      :pageName="mixWB('UNITS')" />

    <PageLoading
      v-if="isLoading"
      :isLoading="isLoading" />

    <div
      v-if="!isLoading"
      class="InnerPage js-inner-page">

      <!-- Page title -->
      <h1
        v-if="index === 0"
        class="js-headline"
        id="units">5. {{ mixWB('UNITS_AND_PCB_SCHEMES') }}</h1>

      <!-- BBR data -->
      <UnitBBRSchema
        :unit="unit"
        :address="address" />

      <!-- Images -->
      <div class="ImageList">
        <template
          v-for="(image, index) in unitProfileImages">
          <div
            v-if="index < 3"
            :key="image.base.path"
            class="Image">
            <MultiImage
              :image="image" />
          </div>

        </template>
      </div>

      <!-- PCB schema -->
      <UnitPCBSchema :unit="unit" />

      <span class="PCBCaption">{{ mixWB('PCB_SCHEME_REPORT_CAPTION_TEXT') }}</span>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageTag from '@/components/PDF/PageTag.vue'
import PageLoading from '@/components/PDF/PageLoading.vue'
import UnitBBRSchema from '@/components/PDF/Report/UnitBBRSchema.vue'
import MultiImage from '@/components/Images/MultiImage.vue'
import UnitPCBSchema from '@/components/PDF/Report/UnitPCBSchema.vue'
import Footer from '@/components/PDF/Footer'
import { mapGetters } from 'vuex'

export default {
  name: 'UnitPage',
  props: {
    index: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    unit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'addressImages',
      'currentScreeningData',
    ]),
    projectAddress() {
      if (!this.currentScreeningData) {
        return {}
      }

      return this.currentScreeningData.address
    },
    address() {
      return `${
        this.projectAddress.address
      }, ${
        this.projectAddress.postalCode
      } ${
        this.projectAddress.city
      }`
    },
    unitImages() {
      if (!this.addressImages?.units) {
        return {}
      }

      return this.addressImages.units[this.unit.id]
    },
    unitProfileImages() {
      return this.unitImages.profile || []
    },
  },
  components: {
    PageTag,
    PageLoading,
    UnitBBRSchema,
    MultiImage,
    UnitPCBSchema,
    Footer,
  },

}
</script>

<style lang="stylus" scoped>
  .UnitPage
    page()

  .InnerPage
    innerPageReport()

  .ImageList
    display flex
    justify-content center
    margin-bottom 20px
    .Image
      box(200px)
      margin 0 10px
      border 1px solid $color_grey_lightest

  .PCBCaption
    color $color_grey
    font-size 0.75rem
    font-style italic
</style>
