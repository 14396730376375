<template>
  <div
    id="floor-plans"
    class="FloorPlans">
    <div
      v-for="pageNumber of amountPagesWithFloorPlans"
      :key="pageNumber"
      class="Page">
      <PageTag
        v-if="pageNumber === 1"
        :pageName="mixWB('FLOOR_PLANS')" />
      <div
        class="InnerPage js-inner-page">
        <h1
          v-if="pageNumber === 1"
          class="js-headline"
          id="floor-plans">7. {{ mixWB('FLOOR_PLANS') }}</h1>
        <FloorPlanWithSamples
          v-if="getUnit(pageNumber, 0)"
          :landscapeMode="false"
          :unit="getUnit(pageNumber, 0)" />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import PageTag from '@/components/PDF/PageTag.vue'
import Footer from '@/components/PDF/Footer.vue'
import FloorPlanWithSamples from '@/components/PDF/Report/FloorPlanWithSamples.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FloorPlans',
  computed: {
    ...mapGetters([
      'addressImages',
      'selectedUnits',
    ]),
    unitsWithFloorPlans() {
      return this.selectedUnits.filter(
        (unit) => unit.hasFloorPlan(),
      )
    },
    amountPagesWithFloorPlans() {
      return this.unitsWithFloorPlans.length
    },
  },
  methods: {
    getUnit(pageNumber, index) {
      return this.unitsWithFloorPlans[pageNumber * 1 - (1 - index)]
    },
  },
  components: {
    PageTag,
    Footer,
    FloorPlanWithSamples,
  },

}
</script>

<style lang="stylus" scoped>
  .Page
    page()

  .InnerPage
    innerPageReport()
</style>
