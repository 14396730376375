<template>
  <div class="UnitPCBSchema">
    <!-- Headline -->
    <div class="Row Headline">
      <span class="Question">{{ mixWB('PCB_SCREENING') }}</span>
      <span class="Yes">{{ mixWB('YES') }}</span>
      <span class="No">{{ mixWB('NO') }}</span>
      <span class="Comment">{{ mixWB('DONT_KNOW') }} / {{ mixWB('COMMENTS') }}</span>
    </div>

    <!-- Rows -->
    <div
      class="Row"
      v-for="answer in psbScreeningAnswers"
      :key="answer.id">
      <span class="Question">{{ answer.text }}</span>
      <span class="Yes">{{ answer.yes }}</span>
      <span class="No">{{ answer.no }}</span>
      <span class="Comment">{{ answer.dontKnow }} {{ answer.comment }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UnitPCBSchema',
  props: {
    unit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'pcbScreeningQuestions',
      'screeningPCBScreenings',
    ]),
    unitPCBScreening() {
      if (!this.screeningPCBScreenings) {
        return {}
      }
      const screening = this.screeningPCBScreenings.find(
        (x) => x.id === this.unit.id,
      )

      return screening ? screening.data : {}
    },
    psbScreeningAnswers() {
      return this.pcbScreeningQuestions.reduce((prev, question) => {
        if (!this.unitPCBScreening[question.id]) {
          return prev
        }

        const data = {
          id: question.id,
          text: '',
          no: '',
          yes: '',
          dontKnow: '',
          comment: '',
        }

        const { answer } = this.unitPCBScreening[question.id]

        data.text = this.mixWB(question.wordbook)
        data.no = answer === 0 ? 'X' : ''
        data.yes = answer === 1 ? 'X' : ''
        data.dontKnow = answer === 2 ? 'X' : ''
        data.comment = this.unitPCBScreening[question.id].comment || ''

        if (data.dontKnow && data.comment) {
          data.dontKnow = 'X - '
        }

        prev.push(data)
        return prev
      }, [])
    },
  },
}
</script>

<style lang="stylus" scoped>
  .UnitPCBSchema
    border 1px solid $color_grey_dark
    margin-bottom 5px

  .Row
    display flex
    align-items center
    padding 5px 10px
    &:nth-child(even)
      background-color $color_grey_lightest
    &.Headline
      background-color $color_grey_dark
      padding 10px
      span
        color #fff
      .Question
        font-size 1rem

  .Question
    flex-grow 2

  .Yes,
  .No
    width 50px
    flex-shrink 0
    text-align center

  .Comment
    width 190px
    flex-shrink 0

  .PCBCaption
    color $color_grey
    font-size 0.75rem
    font-style italic
</style>
