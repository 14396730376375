<template>
  <div class="FrontPage">
    <PageTag :pageName="mixWB('FRONT_PAGE')" />

    <PageLoading
      v-if="isLoading"
      :isLoading="isLoading" />

    <div
      v-if="!isLoading"
      class="InnerPage">
      <!-- Top -->
      <div
        class="Top">
        <PDFImage
          :path="frontPageImagePath"
          className="BGImage" />

        <PDFImage
          :path="currentAccount.images.mainLogo"
          className="Logo" />
      </div>

      <!-- Bottom -->
      <div class="Bottom">
        <div class="House">
          <div class="Roof"/>
          <div class="Wall"/>
          <div class="Chimney"/>
        </div>

        <div class="AddressWrap">
          <span class="Title">{{ mixWB('PROJECT_ADDRESS') }}</span>
          <span class="Address">{{ projectAddress.address }}</span>
          <span class="Address">{{ projectAddress.postalCode }} {{ projectAddress.city }}</span>
          <span v-if="getBuildingNames()">({{ getBuildingNames() }})</span>
        </div>

        <div class="InfoWrap">
          <span class="Title">{{ mixWB('ENVIRONMENTAL_SCREENING_AND_MAPPING_REPORT') }}</span>
          <span class="SubTitle">{{ mixWB('FOR_DEPOSITS_OF_TEXT') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageLoading from '@/components/PDF/PageLoading.vue'
import PageTag from '@/components/PDF/PageTag.vue'
import PDFImage from '@/components/PDFImage.vue'

export default {
  name: 'FrontPage',
  data() {
    return {
      isLoading: true,
      frontPageImageURL: null,
    }
  },
  computed: {
    ...mapGetters([
      'addressImages',
      'addressImagesLoaded',
      'currentScreeningData',
      'currentScreeningLoaded',
      'currentAccount',
      'selectedUnits',
    ]),
    addressProfileImages() {
      if (!this.addressImages?.overview?.profile) {
        return []
      }

      return this.addressImages.overview.profile
    },
    projectAddress() {
      return this.currentScreeningData.address
    },
    frontPageImagePath() {
      return this.addressProfileImages[0].base.path
    },
  },
  methods: {
    setReportTitle() {
      document.title = this.mixGetReportTitle()
    },
    getBuildingNames() {
      return this.currentScreeningData.getBuildingNames().join(', ')
    },
  },
  components: {
    PageTag,
    PageLoading,
    PDFImage,
  },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (
        !this.addressImagesLoaded
        || !this.currentScreeningLoaded
      ) {
        return
      }

      clearInterval(waitForLoad)
      this.isLoading = false

      this.setReportTitle()
    }, 100)
  },
  destroyed() {
    document.title = 'Milva Pro'
  },
}
</script>

<style lang="stylus" scoped>
  .FrontPage
    page()
    padding 0
    span
      color #fff

  .InnerPage
    display flex
    flex-direction column
    height 100%

  .Top
    position relative
    width 100%
    height 790px
    flex-shrink 0
    background-color $color_grey
    background-size cover
    img
      display block
    .BGImage
      max-width 100%
      max-height 100%
    .Logo
      position absolute
      top 40px
      left 40px
      height 40px
      width 180px

  .Bottom
    position relative
    background-color $color_report_green
    flex-grow 2
    padding 30px 100px

  .House
    position absolute
    top -230px
    left 70px
    box(400px, 230px)
    overflow hidden
    .Roof
      box(260px)
      background-color $color_report_green
      transform rotateZ(45deg)
      margin-left calc(50% - (260px / 2))
      margin-top 80px
    .Wall
      box(368px, 20px)
      position absolute
      bottom 0
      left calc(50% - 368px / 2)
      background-color $color_report_green
    .Chimney
      box(60px, 100px)
      position absolute
      top 65px
      left 70px
      background-color $color_report_green

  .AddressWrap
    margin-bottom 90px
    .Title
      font-weight bold
      text-transform uppercase
    .Address
      font-size 2.5rem
      line-height 1.25

  .InfoWrap
    .Title
      font-weight bold
      text-transform uppercase
      padding-bottom 2px
    .SubTitle
      text-transform uppercase
</style>
