<template>
  <div
    id="buildings"
    class="Units">

    <UnitPage
      v-for="(unit, index) in topUnits"
      :key="unit.unitID"
      :index="index"
      :isLoading="isLoading"
      :unit="unit" />
  </div>
</template>

<script>
import UnitPage from '@/components/PDF/Report/UnitPage.vue'
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'

export default {
  name: 'Units',
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters([
      'selectedUnits',
    ]),
    topUnits() {
      return this.selectedUnits.filter((unit) => !unit.hasParent())
    },
  },
  methods: {
    sendNumberOfBuildings() {
      if (this.selectedUnits.length > 1) {
        EventBus.$emit('building-extra-pages', this.selectedUnits.length - 1)
      }
    },
  },
  components: {
    UnitPage,
  },
  mounted() {
    this.isLoading = false
    this.sendNumberOfBuildings()
  },

}
</script>

<style lang="stylus" scoped>
  .Units
    display block
</style>
