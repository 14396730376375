import { render, staticRenderFns } from "./Units.vue?vue&type=template&id=f95f64ee&scoped=true&"
import script from "./Units.vue?vue&type=script&lang=js&"
export * from "./Units.vue?vue&type=script&lang=js&"
import style0 from "./Units.vue?vue&type=style&index=0&id=f95f64ee&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f95f64ee",
  null
  
)

export default component.exports