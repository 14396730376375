<template>
  <div class="GeneralInformation">
    <PageTag :pageName="mixWB('GENERAL_INFORMATION')" />

    <PageLoading
      v-if="isLoading"
      :isLoading="isLoading" />

    <div
      v-if="!isLoading"
      class="InnerPage js-inner-page">
      <div
        id="introduction"
        class="Section">
        <h1
          class="js-headline"
          id="inspection">1. {{ mixWB('INTRODUCTION') }}</h1>
        <p>
          {{ builder && builder.fullName }} har anmodet {{ currentAccount.name }} om at foretage en
          miljøundersøgelse og forureningskortlægning af bygningerne på ejendommen beliggende
          {{ projectAddress.address }}, {{ projectAddress.postalCode }} {{ projectAddress.city }}
          på baggrund af forestående {{ projectTypeText }}.
        </p>
        <p>
          Formålet med nærværende undersøgelse har været at identificere bygningsmaterialer,
          som kan indeholde følgende miljøskadelige stoffer:
        </p>
        <ul>
          <li>Asbest</li>
          <li>PCB</li>
          <li>Tungmetaller (bly, cadmium, chrom, kobber, nikkel, zink og kviksølv)</li>
          <li>PAH’er (tjære)</li>
          <li>Klorerede paraffiner (KP'ere)</li>
        </ul>
        <p>
          Undersøgelsen skal danne grundlag for en indledende vurdering af forekomster af
          ovennævnte stoffer, som ved den forestående nedrivning skal fjernes forud for den
          egentlige hovednedrivning. Endvidere skal der på baggrund af undersøgelsesresultaterne
          gives en generel vurdering af forholdsregler ved demontering samt fjernelse af de
          miljøproblematiske stoffer.
        </p>
        <p>
          Terrænbelægninger mv. på grunden er ikke omfattet af nærværende undersøgelse.
        </p>
      </div>

      <div
        id="strategy"
        class="Section">
        <h1
          class="js-headline"
          id="strategy">2. {{ mixWB('STRATEGY') }}</h1>
        <p>
          Der er udført en indledende miljøundersøgelse af bygningerne. I forbindelse med
          miljøundersøgelsen er der udtaget prøver fra byggematerialer, som ud fra
          opførelsestidspunkt og materialeegenskaber blev vurderet at kunne indeholde
          ovenstående miljøskadelige stoffer.
        </p>
        <p>
          Miljøundersøgelsen har orienterende karakter og skal ikke forstås som en egentlig
          kortlægning af al forekomst og afgrænsning af ovennævnte stoffer.
        </p>
      </div>

      <div
        id="sampling"
        class="Section">
        <h1
          class="js-headline"
          id="sampling">3. {{ mixWB('SAMPLING') }}</h1>
        <p>
          Der er i forbindelse med miljøundersøgelsen i alt udtaget {{ samplesCount }}
          materialeprøver til analyse.
          <br/>
          Prøvetyper fremgår af tabellen i afsnit 6.
          <br/>
          Prøveudtagningssteder fremgår af fotodokumentation i afsnit 7.
          <br/>
          De udtagne materialeprøver vurderes at være repræsentative for alle tilsvarende
          materialer i hele den pågældende bebyggelse, medmindre andet er angivet i afsnit 8.
        </p>
      </div>

      <div
        id="pcb-scheme-intro"
        class="Section">
        <h1
          class="js-headline"
          id="pcb-screening-scheme">4. {{ mixWB('EXPLANATION_OF_PCB_SCREENING_SCHEME') }}</h1>
        <p>
          Er bygningen eller dele heraf opført eller renoveret i perioden fra 1950 til 1977,
          er der risiko for, at der kan være anvendt PCB-holdigt materiale.
        </p>
        <p>
          Der skal derfor foretages en screening af bygningen eller dele heraf,
          inden nedrivning eller byggearbejde påbegyndes. Hvis der ikke kan svares ’nej’ til alle
          spørgsmål, er der behov for kortlægning af en eventuel forekomst og spredning af PCB.
        </p>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/PDF/Footer.vue'
import PageLoading from '@/components/PDF/PageLoading.vue'
import PageTag from '@/components/PDF/PageTag.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'GeneralInformation',
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters([
      'metaData',
      'currentScreeningMetaDataLoaded',
      'currentScreeningData',
      'currentScreeningLoaded',
      'screeningSamples',
      'currentScreeningSamplesLoaded',
      'currentAccount',
    ]),
    builder() {
      return this.metaData.builder || false
    },
    projectAddress() {
      return this.currentScreeningData.address
    },
    samplesCount() {
      return this.screeningSamples.filter((x) => x.sampleNumber).length
    },
    projectTypeText() {
      if (this.metaData.projectType === 'renovation') {
        return this.mixWB('RENOVATION_2')
      }

      return this.mixWB('DEMOLITION_2')
    },
  },
  components: {
    PageTag,
    PageLoading,
    Footer,
  },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (
        !this.currentScreeningMetaDataLoaded
        || !this.currentScreeningLoaded
        || !this.currentScreeningSamplesLoaded
      ) {
        return
      }

      clearInterval(waitForLoad)
      this.isLoading = false
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .GeneralInformation
    page()
    p
      padding-bottom 15px

  .InnerPage
    innerPageReport()

  .Section
    margin-bottom 20px
    ul
      margin-left 20px
      margin-bottom 15px
</style>
