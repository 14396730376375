<template>
  <div
    id="buildings"
    class="Buildings">
    <PageTag :pageName="mixWB('BUILDINGS')" />

    <BuildingPage
      v-for="(building, index) in selectedBuildings"
      :key="building.buildingID"
      :index="index"
      :isLoading="isLoading"
      :building="building" />
  </div>
</template>

<script>
import PageTag from '@/components/PDF/PageTag.vue'
import { mapGetters } from 'vuex'
import BuildingPage from '@/components/PDF/Report/BuildingPage.vue'
import EventBus from '@/EventBus'

export default {
  name: 'Buildings',
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters([
      'addressImagesLoaded',
      'currentScreeningBuildingsLoaded',
      'currentScreeningPCBScreeningLoaded',
      'selectedBuildings',
    ]),
  },
  methods: {
    sendNumberOfBuildings() {
      if (this.selectedBuildings.length > 1) {
        EventBus.$emit('building-extra-pages', this.selectedBuildings.length - 1)
      }
    },
  },
  components: {
    PageTag,
    BuildingPage,
  },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (
        !this.addressImagesLoaded
        || !this.currentScreeningBuildingsLoaded
        || !this.currentScreeningPCBScreeningLoaded
      ) {
        return
      }

      clearInterval(waitForLoad)
      this.isLoading = false
      this.sendNumberOfBuildings()
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .Buildings
    display block

</style>
