import { mixWB } from '@/globals/javascript/_util/mixins'
import buildingTypeCodes from '@/globals/json/building-type-codes.json'
import ownerAssociations from '@/globals/json/owner-associations.json'

export const getBuildingTitle = (building) => `${
  mixWB('BUILDING')
} ${
  building.data.Bygningsnr
} - ${
  buildingTypeCodes[building.data.BYG_ANVEND_KODE]
}`

export const getBBRDataValue = (building, item) => {
  if (!building || !building.data) {
    return '?'
  }

  // Look into floors data
  if (item.key === 'UTILIZED_ATTIC_AREA' || item.key === 'BASEMENT_AREA') {
    if (!building.floors) {
      return '?'
    }

    if (!building.floors.length) {
      return `0 ${ mixWB('M2') }`
    }

    const basement = building.floors.find((x) => x.Etagebetegn === 'KL')
    const attic = building.floors.find((x) => x.TAGETAGE_ARL_UDNYT > 0)

    if (item.key === 'UTILIZED_ATTIC_AREA') {
      if (!attic) {
        return `0 ${ mixWB('M2') }`
      }

      return `${ attic.TAGETAGE_ARL_UDNYT } ${ mixWB('M2') }`
    }

    if (item.key === 'BASEMENT_AREA') {
      if (!basement) {
        return `0 ${ mixWB('M2') }`
      }

      return `${ basement.SamletAreal } ${ mixWB('M2') }`
    }
  }

  // Rest of data
  let value = building.data[item.key]

  if (item.key === 'Landsejerlavkode') {
    const newValue = ownerAssociations[value]

    if (newValue) {
      value = newValue
    }
  }

  if (!value && (value !== 0 && value !== '0')) {
    return '?'
  }

  if (item.type === 'year') {
    if (!value) {
      if (item.key === 'OMBYG_AAR') {
        return mixWB('NOT_SPECIFIED')
      }
      return '?'
    }
    return `${ mixWB('YEAR') } ${ value }`
  }
  if (item.type === 'm2') {
    return `${ value } ${ mixWB('M2') }`
  }

  return value
}
