<template>
  <div class="CategoryListIntro">
    <p>
      Under besigtigelsen er der konstateret følgende bygningsdele (typer), fordelt på i alt
      {{ categories.length }} overordnede kategorier.
      Typer der hører under flere kategorier er typisk kun beskrevet én gang i den
      primære kategori.
    </p>
    <p>
      Hvor der er risiko for miljøskadelige stoffer er der udtaget en repræsentativ
      materialeprøve til analyse eller der er foretaget en vurdering med udgangspunkt
      i en 'worst case' forekomst af miljøskadelige stoffer. Eventuelle vurderinger
      er vejledende og efter forsigtighedsprincip.
    </p>
    <p>
      Der er ved hver type en vejledende anbefaling for håndtering og bortskaffelse,
      samt anbefaling af eventuelle arbejdsmiljømæssige tiltag.
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CategoryListIntro',
  computed: {
    ...mapGetters([
      'categories',
    ]),
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryListIntro
    margin-bottom 20px
</style>
